import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  useRive,
  Layout,
  Fit,
  Alignment,
} from 'rive-react';

/* CSS */
import "./App.css";
import "./SliderAnimation.css";

/* SVG(s) */
import { ReactComponent as KraylaLogo } from "./imgs/krayla-logo/default_logo.svg";

/* Context */
import MainAppContext from "./data/contexts";

/* Relative Imports */
import { getAvatar } from "./utils";
import LoginFormComponent from "./components/LoginFormComponent/LoginFormComponent.tsx";

/* Views */
import UserView from "./views/user/userLanding";
import DashboardSettings from "./views/dashboard/settings";

/* Icons */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInstagram,
  faYoutube,
  faSpotify,
  faTwitch,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

// Add Fortawesome Icons
library.add(
  faInstagram,
  faYoutube,
  faSpotify,
  faTwitch,
  faTwitter,
  faArrowDown
);

SuperTokens.init({
  appInfo: {
    appName: "http://localhost:3000/",
    apiDomain: "http://localhost:8000",
    websiteDomain: "http://localhost:3000",
    apiBasePath: "/auth",
    websiteBasePath: "/m/login",
  },
  recipeList: [
    Passwordless.init({
      contactMethod: "EMAIL_OR_PHONE",
    }),
    Session.init(),
  ],
});

function App() {
  const [isAuth, setIsAuth] = useState(true);

  return (
    <MainAppContext.Provider value={this}>
      <SuperTokensWrapper>
        <Router>
          <Routes>
            <Route path="/m/about" element={<About />}></Route>
            {/* Auth Required Views */}
            {isAuth ? (
              <Route path="/m/dashboard" element={<Dashboard />}></Route>
            ) : (
              <Route
                path="/m/dashboard"
                element={<UserNotAuthRedirect />}
              ></Route>
            )}
            <Route
              path="/m/login"
              element={<LoginRegister actionType="login" />}
            ></Route>
            <Route
              path="/m/register"
              element={<LoginRegister actionType="register" />}
            ></Route>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="*" element={<UserPagePath />}></Route>
          </Routes>
        </Router>
      </SuperTokensWrapper>
    </MainAppContext.Provider>
  );
}

function Home() {
  const { rive: riveInstance, RiveComponent } = useRive({
      src: 'https://public.rive.app/community/runtime-files/1130-2392-meditation.riv',
      autoplay: true,
      layout: new Layout({
        fit: window.innerWidth < 600 ? Fit.Cover : Fit.Contain,
        alignment: Alignment.Center,
      }),
    });

  return (
    <div className="leading-normal tracking-normal text-gray-900 overflow-x-hidden">
      <div className="h-screen pb-14 bg-right bg-cover">
        <div className="md:w-full md:absolute items-center text-center lg:px-4">
          <div
            className="p-2 bg-blue-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
            role="alert"
          >
            <span className="flex rounded-full uppercase px-2 py-1 text-2xl font-bold mr-3">
              <span aria-label="wave" role="img">
                👋
              </span>
            </span>
            <span className="font-semibold mr-2 text-left flex-auto capitalize">
              Sign up now to extend your wellness journey!
            </span>
          </div>
        </div>
        <div className="flex w-full px-1 py-6 border-b">
          <div className="w-full flex items-left justify-between z-10">
            <div className="w-full flex items-center justify-start">
              <KraylaLogo
                className="home-logo text-sm"
                onClick={() => (window.location.href = "/")}
              />
            </div>
            <div className="w-full flex items-center justify-end">
              <a href="/m/login" className="underline mx-3 font-bold">
                Login
              </a>
              <button
                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => (window.location.href = "/m/register")}
              >
                Register
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center z-20 pt-6 md:pt-6 flex-wrap flex-col md:flex-row items-center">
          <div className="flex flex-col w-full items-center xl:w-2/5 justify-center">
            <h1 className="mt-4 text-7xl md:text-6xl font-bold leading-tight text-left md:text-center slide-in-bottom-h1">
              Enhance
            </h1>
            <h1 className="mb-4 text-7xl md:text-5xl font-bold leading-tight text-left md:text-center slide-in-bottom-h1">
              your
            </h1>
            <h1 className="mb-4 text-7xl md:text-6xl font-bold leading-tight text-left md:text-center slide-in-bottom-h1">
              Wellness
            </h1>
            <p className="leading-normal mx-4 font-semibold capitalize text-base md:text-2xl mb-4 text-left md:text-center slide-in-bottom-subtitle">
              Our instructors are trained in wellness in a magnitude of different practices to bring your wellness to the next level.
            </p>
            <button
              className="animate__animated animate__tada animate__delay-1s bg-[#4288B2] hover:bg-[#4288B2] w-4/6 text-white font-bold py-2 mb-2 self-center px-4 border-b-4 border-[#4288B2] hover:border-[#4288B2] rounded"
              onClick={() => (window.location.href = "/m/register")}
            >
              START FOR FREE
            </button>
          </div>
          <RiveComponent className="w-full sm:h-[32em] md:h-[54em] t-[-8em]"/>
        </div>


        <div className="flex flex-row w-full justify-center w-full text-sm text-left md:text-left fade-in my-2 mx-1">
          <a
            className="text-black-500 no-underline hover:no-underline mr-2"
            href="/"
          >
            &copy; Krayla 2025
          </a>
          <div className="flex self-center items-center justify-center">
            <a className="text-center mr-2 underline break-keep" href="/">
              Terms & Conditions
            </a>
            <a className="text-center underline break-keep" href="/">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return <h2>About</h2>;
}

function UserNotAuthRedirect() {
  return (
    <div className="overflow-visible flex-col items-center justify-center mt-24">
      <h3 className="font-bold text-center">ERROR_NOT_LOGGED_IN</h3>
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-5"
        role="alert"
      >
        <strong className="font-bold">Errr - Seems your not logged in!</strong>
        <span className="block">
          Something seriously bad happened, But we'll point you in the right
          direction!
        </span>
      </div>
    </div>
  );
}

function Dashboard() {
  const [selectedTab, setSelectedTab] = useState("");
  const [user, setUser] = useState({
    currUserName: "default",
    currUserProfileImage: "default",
  });

  const tabSelection = {
    selectedClass:
      "bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold",
    normalClass:
      "bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold",
  };

  const assignSelectedClass = (n_id) => {
    return selectedTab === n_id
      ? tabSelection.selectedClass
      : tabSelection.normalClass;
  };

  const logOutUser = () => {
    console.log("User Log Out");
    return;
  };

  return (
    <div>
      <div className="flex flex-col my-4 px-8 justify-between">
        <div className="flex flex-row my-4 text-center">
          <KraylaLogo
            className="dashboard-logo"
            onClick={() => (window.location.href = "/")}
          />
        </div>
        <div className="flex justify-between space-x-2">
          <div className="flex flex-row my-4 text-center items-center space-x-2">
            <img
              className="dashboard-profile-image justify-center mx-2"
              onClick={() => (window.location.href = "/")}
              src={getAvatar(
                "https://api.dicebear.com/9.x/micah/svg?seed=",
                new Date().toString()
              )}
              alt="Profile Logo"
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/${user.currUserName}`}
            >
              <h2 className="font-bold text-gray-500 underline">{`@${user.currUserName}`}</h2>
            </a>
          </div>
          <div className="flex bottom-0 items-center justify-center my-4">
            <button
              className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
              onClick={logOutUser}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-visible flex items-center justify-center">
        <div className="flex-row w-11/12">
          <ul className="flex border-b">
            <li className="-mb-px mr-1">
              <a
                className={assignSelectedClass("overview")}
                onClick={() => setSelectedTab("overview")}
                href="#overview"
              >
                Overview
              </a>
            </li>
            <li className="mr-1">
              <a
                className={assignSelectedClass("settings")}
                onClick={() => setSelectedTab("settings")}
                href="#settings"
              >
                Settings
              </a>
            </li>
          </ul>
          {selectedTab === "settings" ? (
            <DashboardSettings
              currUserName={user.currUserName}
              currUserProfileImage={user.currUserProfileImage}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function LoginRegister() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex flex-row min-h-screen justify-center items-center">
      <div className="flex-row">
        <span className="img-circle">
          <KraylaLogo
            className="home-logo"
            onClick={() => (window.location.href = "/")}
          />
        </span>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
          {isLoading ? (
            <ScaleLoader size={150} color={"#0087eb"} loading={isLoading} />
          ) : (
            <div id="login-container">
              <LoginFormComponent />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function UserPagePath() {
  return <UserView />;
}

export default App;
export { MainAppContext };
