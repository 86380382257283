import React, { useState } from "react";
import { Modal, Button } from "flowbite-react";
import { QRCode } from "react-qrcode-logo";

import { CompanyIcon, UserTagLineText } from "../../userLanding.css.js";

export function QRCodeRender({
  companyIcon,
  userTagline,
  userQRCodeDescription,
  currUserNameReal,
}) {
  const [openModal, setOpenModal] = useState(false);

  const QRUrlLink = `https://krayla.com` + window.location.pathname;

  return (
    <div>
      <Modal
        position={"center"}
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        {/* <Modal.Header>QR CODE</Modal.Header> */}
        <Modal.Body>
          <div className="flex justify-center">
            <div className="w-full p-4 rounded-lg bg-two flex flex-col space-y-8">
              <div className="flex flex-col space-x-2 items-center">
                {companyIcon && <CompanyIcon src={companyIcon} alt="Profile" />}
                <h2 className="text-md font-normal text-slate-600 ">
                  @{currUserNameReal}
                </h2>
              </div>

              <div className="flex flex-col space-y-2 justify-center items-center px-6">
                {userTagline && (
                  <UserTagLineText
                    className="text-lg mb-4"
                    id="user-name"
                  >{`${userTagline}`}</UserTagLineText>
                )}
                <div className="flex flex-col justify-center items-center py-12">
                  <QRCode
                    value={`${QRUrlLink}`}
                    logoImage={
                      "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/8_dPYd0kANM.png?updatedAt=1725296268045"
                    }
                    logoOpacity={0.4}
                    removeQrCodeBehindLogo={true}
                    size={300}
                  />
                  <h2 className="text-xs font-normal text-slate-600 ">
                    {QRUrlLink}
                  </h2>
                </div>
                <h2 className="text-xs font-normal text-slate-600 ">
                  {userQRCodeDescription}
                </h2>
              </div>
              <button className="px-4 py-2 rounded-md border border-black bg-white text-neutarl-700 text-md hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200">
                Scan to View
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>Done</Button>
          <Button onClick={() => window.print()}>Print</Button>
          {/* <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button> */}
        </Modal.Footer>
      </Modal>
      <div className="absolute top-4 left-4" onClick={() => setOpenModal(true)}>
        <button className="text-white font-bold py-0.5 px-0.5">
          <span className="bg-blue-500 hover:bg-blue-600 material-symbols-outlined rounded-sm shadow-lg">
            qr_code
          </span>
        </button>
      </div>
    </div>
  );
}
