class PublicUserRepository {
  /*
  // Example usage:
    const PublicUser = new PublicUserRepository();
    fetcher.fetchData('user_handler_id'); // Fetch a single user
  */
  constructor() {
    this.baseUrl = process.env.REACT_APP_HTTP_BACKEND_API_HOST;
  }

  async getUser(handlerId) {
    const url = `${this.baseUrl}/api/public_user?handle=${handlerId}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
}

export { PublicUserRepository };
