import React, { useState, useEffect } from "react";
import { Modal, Button } from "flowbite-react";
import { getTimeAMPMFormat } from "../../../../utils.js";

const moment = require("moment");

export const ReserveOnlineClass = ({
  data,
  currUserNameReal,
  userTagline,
  urlUserName,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const liveClassToken = new URL(window.location.href).searchParams.get(
    "liveClassToken"
  );

  const classConfig = data?.slots[0] ?? {};
  const whereByIntegration = { url: classConfig?.location };
  const calendlyIntegration = {
    url: classConfig?.calendarLink,
    text: "Join My Upcoming Class",
    color: "#0069ff",
    textColor: "#ffffff",
  };

  const morningTimeSlot = [4, 7, 11, 14, 18, 21, 25, 28].map((id) => {
    return {
      id,
      startTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(
          9,
          0,
          0,
          0
        )
      ),
      endTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(
          9,
          45,
          0,
          0
        )
      ),
    };
  });

  const DawnTimeSlot = [4, 7, 11, 14, 18, 21, 25, 28].map((id) => {
    return {
      id,
      startTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(
          16,
          0,
          0,
          0
        )
      ),
      endTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setHours(
          16,
          45,
          0,
          0
        )
      ),
    };
  });

  const availableTimeslots = [...morningTimeSlot, ...DawnTimeSlot];

  const handleClick = () => {
    if (calendlyIntegration.url) {
      window.Calendly.showPopupWidget(
        calendlyIntegration.url,
        "PopupWidget",
        {}
      );
    } else {
      console.warn("NO LIVE CLASS SLOT PRESENT");
    }
    // Future Integrations (On )
    // setOpenModal(true);
  };

  const handleJoinLiveClass = () => {
    window.location.href = whereByIntegration.url;
  };

  useEffect(() => {
    if (liveClassToken) {
      setOpenModal(true);
    } else {
      window?.Calendly?.initBadgeWidget(calendlyIntegration);
    }
  }, []);

  const confirmEventSelection = (eventBooking) => {
    const targetEvent = selectedEvent[0];
    const targetEventMinutes =
      (targetEvent.availableTimeslot.endTime -
        targetEvent.availableTimeslot.startTime) /
      60 /
      1000;
    console.log(targetEvent, targetEventMinutes / 60);

    const videoUrl = new URL(
      `/${urlUserName}`,
      new URL(window.location.href).origin
    );
    videoUrl.searchParams.set("liveClassToken", true);
    videoUrl.searchParams.set(
      "classId",
      targetEvent.availableTimeslot.startTime.toString()
    );

    console.warn("---->", moment().format("YYYY-MM-DDTHH:mm:ssZ"));
    const calendlyLinkUrl = new URL(
      `https://calendly.com/insertcod3/yoga/${moment().format(
        "YYYY-MM-DDTHH:mm:ssZ"
      )}`
    );
    console.warn(calendlyLinkUrl.toString());
    /*
    const event = {
      title: `${currUserNameReal}'s - Yoga Class Online`,
      description: `${userTagline} \nMeet Join Link - <a href="${videoUrl.toString()}" id="ow43" __is_owner="true">${currUserNameReal}'s Class Link</a> \n\nMeeting Link: <a href="${videoUrl.toString()}" id="ow43" __is_owner="true">${videoUrl.toString()}</a>`,
      start: targetEvent.startTime,
      duration: [targetEventMinutes, "minutes"],
    };
    const googleICSLink = googleICSCalendar(event);
    window.open(googleICSLink, '_blank').focus();
    */
    setConfirmationModal(false);
  };

  return (
    <div className="flex w-full justify-center">
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        {liveClassToken ? (
          <Modal.Header>Join Today's Class</Modal.Header>
        ) : (
          <Modal.Header>Join Upcoming Class</Modal.Header>
        )}
        <div className="space-y-6">
          {liveClassToken ? (
            <Modal.Body>
              <div className="flex flex-col items-center">
                <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                  Mindful Pre-meeting Check-in ❤️
                </h2>
                <h2 className="mb-2 text-lg text-center font-base underline text-gray-900 dark:text-white">
                  Here are some mindful tips to consider before joining today's
                  video chat
                </h2>
                <ul className="w-full space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                  <li>
                    Take 5-10 deep breaths to calm your nerves and center
                    yourself.
                  </li>
                  <li>
                    Find a quiet, private space with minimal distractions.
                  </li>
                  <li>
                    Test your audio and video to avoid last-minute technical
                    stress.
                  </li>

                  <li>
                    Set an intention for the call, such as being present and
                    engaged.
                  </li>
                  <li>
                    Take a moment to notice your physical presence, ex.
                    adjusting your posture and seating for comfort.
                  </li>
                </ul>
                {/* Start Divider */}
                <div className="w-full h-[0.1em] mt-6 mb-4 border-dashed border-t border-gray-500 divide-y divide-dashed" />
                {/* End Divider */}
                <button
                  onClick={handleJoinLiveClass}
                  className="shadow-[0_4px_14px_0_rgb(0,118,255,39%)] hover:shadow-[0_6px_20px_rgba(0,118,255,23%)] hover:bg-[rgba(0,118,255,0.9)] px-8 py-2 bg-[#0070f3] rounded-md text-white font-light transition duration-200 ease-linear"
                >
                  Join Class
                </button>
              </div>
            </Modal.Body>
          ) : (
            <>
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/insertcod3/morning-flow-yoga"
              ></div>
              <script
                type="text/javascript"
                src="https://assets.calendly.com/assets/external/widget.js"
                async
              ></script>
              {/* <ScheduleMeeting
                borderRadius={10}
                primaryColor="#3f5b85"
                eventDurationInMinutes={45}
                availableTimeslots={availableTimeslots}
                onStartTimeSelect={(eventEmit) => {
                  setSelectedEvent([eventEmit])
                  setConfirmationModal(true)
                }}
              /> */}
            </>
          )}
        </div>
        {/* <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>I accept</Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={confirmationModal}
        size="md"
        onClose={() => setConfirmationModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              <span className="material-symbols-outlined text-4xl">
                event_upcoming
              </span>
            </h3>
            <h3 className="text-lg font-normal text-gray-500 dark:text-gray-400">
              You have selected the event for:
            </h3>
            <span className="mb-5 text-lg font-bold text-gray-500 dark:text-gray-400">
              {selectedEvent.map((userSelectedEvent) => {
                return getTimeAMPMFormat(userSelectedEvent.startTime);
              })}
            </span>
            {/* Start Divider */}
            <div className="w-full h-[0.1em] mt-6 mb-4 border-dashed border-t border-gray-500 divide-y divide-dashed" />
            {/* End Divider */}
            <div className="flex justify-center gap-4">
              <Button color="success" onClick={confirmEventSelection}>
                {"Yes, I'm sure"}
              </Button>
              <Button
                color="failure"
                onClick={() => setConfirmationModal(false)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <button
        onClick={handleClick}
        className="shadow-[0_4px_14px_0_rgb(0,118,255,39%)] hover:shadow-[0_6px_20px_rgba(0,118,255,23%)] hover:bg-[rgba(0,118,255,0.9)] px-8 py-2 bg-[#0070f3] rounded-md text-white font-light transition duration-200 ease-linear"
      >
        {liveClassToken ? "Join My Class" : "Join My Upcoming Online Class"}
      </button>
    </div>
  );
};
