import React from "react";

import { PartnerShipImage } from "../../userLanding.css.js";

export function PartnershipInfo({ partnerships }) {
  return (
    <section className="bg-white">
      <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
        <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 md:text-4xl">
          Happily In Partnership with
        </h2>
        <div className="flex flex-wrap justify-center gap-2 justify-center items-center gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-3">
          {[...partnerships].map((item, idx) => {
            return (
              <a
                key={idx}
                href="#"
                className="flex h-1/2 w-1/4 justify-center items-center"
              >
                <PartnerShipImage src={item.image} />
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
}
