import React, { useState, useEffect } from "react";
import { Modal, Tabs, Dropdown } from "flowbite-react";

import { TallyIframe } from "./RatesCarousel.css.ts";

const loadForm = () => {
  const widgetScriptSrc = "https://tally.so/widgets/embed.js";

  const load = () => {
    // Load Tally embeds
    if (typeof document.Tally !== "undefined") {
      console.warn("Load loadEmbeds");
      document.Tally.loadEmbeds();
      return;
    }

    // Fallback if window.Tally is not available
    document
      .querySelectorAll("iframe[data-tally-src]:not([src])")
      .forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
  };

  console.warn("Tally Script Load");
  const script = document.createElement("script");
  script.src = widgetScriptSrc;
  script.onload = load;
  script.onerror = load;
  document.body.appendChild(script);
};

export function RatesCarousel({ data }) {
  const [openModal, setOpenModal] = useState(false);

  // organizational
  const [configuration, setConfiguration] = useState(data);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      console.log(e?.data);
      if (String(e?.data)?.indexOf("Tally.FormSubmitted") > -1) {
        // const payload = JSON.parse(e.data);

        // Navigate to Payment Processor
        const url = new URL(window.location.href);
        const curr_url_payment_type = url.searchParams.get("payment_type");

        window.location.href = configuration[curr_url_payment_type].paymentLink;
      }
    });
  }, []);

  const applyRatesToBuilder = (id, paymentIdx, paymentDataIdx, data) => {
    let copyOfConfiguration = { ...configuration };
    configuration[id].payments[paymentIdx].data[paymentDataIdx].dropdown.value =
      data;
    configuration[id].paymentLink = data.paymentLink;
    configuration[id].payments[paymentIdx].price = data.price;
    console.log(copyOfConfiguration, data);
    setConfiguration(copyOfConfiguration);
  };

  const handlePaymentClick = (paymentType) => {
    const url = new URL(window.location.href);
    url.searchParams.set("payment_type", paymentType);
    window.history.pushState(null, "", url.toString());

    setOpenModal(true);
    loadForm();
  };

  const makeTallySrcUrl = () => {
    const url = new URL(window.location.href);
    let payment_type = url.searchParams.get("payment_type");
    return configuration[payment_type]
      ? `${configuration[payment_type].intakeForm}?transparentBackground=1`
      : "";
  };

  return (
    <div id="user_rates">
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        position={"center"}
        size={"7xl"}
      >
        <Modal.Header>Booking Inquiry</Modal.Header>
        <Modal.Body
          className="overflow-y-hidden p-0 h-scroll"
          style={{ height: "100%" }}
        >
          <div className="h-full">
            <TallyIframe
              data-tally-src={makeTallySrcUrl()}
              width="100%"
              height="100%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="HEALTH AND FITNESS  QUESTIONNAIRE"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={() => setOpenModal(false)}>I accept</Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button> */}
        </Modal.Footer>
      </Modal>
      <Tabs
        id="user_rates_tabs"
        aria-label="Default tabs"
        variant="default"
        className="justify-center	border-none mt-2"
      >
        {Object.keys(configuration).map((paymentType) => {
          return (
            <Tabs.Item
              name="payment_rates"
              key={"rates_payment_type_id" + paymentType}
              className="justify-center border-none mt-2"
              active
              title={configuration[paymentType].label}
            >
              <main className="w-full pb-5">
                <div className="max-w-md mx-auto mb-14 text-center">
                  <h1 className="text-4xl font-semibold mb-3 lg:text-5xl">
                    <span className="">⭐</span>
                    <span className="text-fuchsia-600">Wellness</span>
                    <span className="text-fuchsia-600"> Plans</span>
                    <span className="">⭐</span>
                  </h1>
                  <p className="text-2xl text-zinc-950 font-medium">
                    Let's invest into your wellness journey together
                  </p>
                </div>

                <div className="flex flex-col justify-between items-center sm:flex-col lg:items-start md:flex-col lg:items-start lg:flex-col lg:items-start 2xl:flex-row lg:items-start">
                  {configuration[paymentType].payments.map(
                    (paymentConfig, paymentIdx) => {
                      if (paymentConfig.isPrimary) {
                        return (
                          <div className="w-full flex-1 p-8 order-1 shadow-xl rounded-3xl bg-gray-900 text-gray-400 sm:w-11/12 lg:w-full lg:order-2 lg:mt-0">
                            <div className="mb-8 pb-8 flex items-center border-b border-gray-600">
                              <div className="flex flex-col">
                                <div className="flex">
                                  <img
                                    src="https://res.cloudinary.com/williamsondesign/abstract-2.jpg"
                                    alt=""
                                    className="rounded-3xl w-20 h-20"
                                  />
                                  <div className="ml-5">
                                    <span className="block text-3xl font-semibold text-white">
                                      {paymentConfig.label}
                                    </span>
                                    <span>
                                      <span className="font-medium text-xl align-top">
                                        $&thinsp;
                                      </span>
                                      <span className="text-3xl font-bold text-white">
                                        {paymentConfig.price}{" "}
                                      </span>
                                    </span>
                                    <span className="font-medium">
                                      / person
                                    </span>
                                  </div>
                                </div>
                                <div className="flex mt-4">
                                  <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
                                    {paymentConfig.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <ul className="mb-10 font-medium text-xl">
                              {paymentConfig.data.map(
                                (paymentItem, paymentItemIdx) => {
                                  return (
                                    <li className="flex mb-6">
                                      <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                                      <span className="flex items-center ml-3 space-x-2">
                                        <span className="text-nowrap text-white">
                                          {paymentItem.text}
                                        </span>
                                        <span className="text-white">
                                          {paymentItem.dropdown ? (
                                            <Dropdown
                                              className="group bg-blue-400 relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent bg-cyan-700 text-white focus:ring-4 focus:ring-cyan-300 enabled:hover:bg-cyan-800 dark:bg-cyan-600 dark:focus:ring-cyan-800 dark:enabled:hover:bg-cyan-700 rounded-lg w-max"
                                              label={
                                                paymentItem.dropdown.value
                                                  ? paymentItem.dropdown.value
                                                      .text
                                                  : paymentItem.dropdown
                                                      .values[0].text
                                              }
                                            >
                                              {paymentItem.dropdown.values.map(
                                                (paymentItemValue) => {
                                                  return (
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        applyRatesToBuilder(
                                                          paymentType,
                                                          paymentIdx,
                                                          paymentItemIdx,
                                                          paymentItemValue
                                                        )
                                                      }
                                                    >
                                                      {paymentItemValue.text}
                                                    </Dropdown.Item>
                                                  );
                                                }
                                              )}
                                            </Dropdown>
                                          ) : null}
                                        </span>
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            <button
                              onClick={() => handlePaymentClick(paymentType)}
                              className="flex justify-center items-center bg-indigo-600 rounded-xl py-6 px-4 text-center text-white text-2xl"
                            >
                              Book Now
                              <img
                                src="https://res.cloudinary.com/williamsondesign/arrow-right.svg"
                                className="ml-2"
                              />
                            </button>
                          </div>
                        );
                      }

                      return (
                        <div className="w-full flex-1 mt-8 p-8 order-3 bg-white shadow-xl rounded-3xl sm:w-11/12 lg:w-full lg:order-3">
                          <div className="mb-7 pb-7 flex items-center border-b border-gray-300">
                            <div className="flex flex-col">
                              <div className="flex">
                                <img
                                  src="https://res.cloudinary.com/williamsondesign/abstract-3.jpg"
                                  alt=""
                                  className="rounded-3xl w-20 h-20"
                                />
                                <div className="ml-5">
                                  <span className="block text-2xl font-semibold">
                                    {paymentConfig.label}
                                  </span>
                                  <span>
                                    <span className="font-medium text-gray-500 text-xl align-top">
                                      $&thinsp;
                                    </span>
                                    <span className="text-3xl font-bold">
                                      {paymentConfig.price}{" "}
                                    </span>
                                  </span>
                                  <span className="text-gray-500 font-medium">
                                    / person
                                  </span>
                                </div>
                              </div>
                              <div className="flex mt-4">
                                <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
                                  {paymentConfig.description}
                                </p>
                              </div>
                            </div>
                          </div>

                          <ul className="mb-7 font-medium text-gray-500">
                            {paymentConfig.data.map(
                              (paymentItem, paymentItemIdx) => {
                                return (
                                  <li className="flex mb-6">
                                    <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                                    <span className="flex items-center ml-3 space-x-2">
                                      <span className="text-nowrap text-black">
                                        {paymentItem.text}
                                      </span>
                                      <span className="text-black">
                                        {paymentItem.dropdown ? (
                                          <Dropdown
                                            label={
                                              paymentItem.dropdown.value
                                                ? paymentItem.dropdown.value
                                                    .text
                                                : paymentItem.dropdown.values[0]
                                                    .text
                                            }
                                          >
                                            {paymentItem.dropdown.values.map(
                                              (paymentItemValue) => {
                                                return (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      applyRatesToBuilder(
                                                        paymentType,
                                                        paymentIdx,
                                                        paymentItemIdx,
                                                        paymentItemValue
                                                      )
                                                    }
                                                  >
                                                    {paymentItemValue.text}
                                                  </Dropdown.Item>
                                                );
                                              }
                                            )}
                                          </Dropdown>
                                        ) : null}
                                      </span>
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                          <button
                            onClick={() => handlePaymentClick(paymentType)}
                            className="flex justify-center items-center bg-indigo-600 rounded-xl py-5 px-4 text-center text-white text-xl"
                          >
                            Book Now
                            <img
                              src="https://res.cloudinary.com/williamsondesign/arrow-right.svg"
                              className="ml-2"
                            />
                          </button>
                        </div>
                      );
                    }
                  )}
                </div>
              </main>
            </Tabs.Item>
          );
        })}
      </Tabs>
    </div>
  );
}
