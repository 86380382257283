import React, { useState } from "react";
import { Badge, Tabs } from "flowbite-react";

const HaloBreathEffect = () => {
  return (
    <div className="stage">
      <div className="scene">
        <div className="halo">
          <i></i>
          <span className="halo-star"></span>
          <span className="halo-star"></span>
          <span className="halo-star"></span>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div className="lantern">
          <div className="lantern-handle"></div>
          <div className="lantern-inner">
            <div className="lantern-chain"></div>
            <div className="lantern-head"></div>
            <div className="lantern-body">
              <div className="lantern-spark"></div>
              <div className="lantern-spark"></div>
              <div className="lantern-spark"></div>
              <div className="lantern-flame"></div>
            </div>
            <div className="lantern-base"></div>
          </div>
          <div className="planets">
            <div className="planet"></div>
            <div className="planet"></div>
            <div className="planet"></div>
            <div className="planet"></div>
            <div className="planet"></div>
            <div className="planet"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ContentSubscriptions = ({ data }) => {
  const [audioPlayer, setAudioPlayer] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudio = (content) => {
    // console.warn(
    //   content.src,
    //   audioPlayer.src,
    //   audioPlayer.src.endsWith(content.src)
    // );
    if (audioPlayer.src.endsWith(content.src) === false) {
      audioPlayer.src = content.src;
    }
    if (audioPlayer && !audioPlayer.paused) {
      audioPlayer.pause();
      setIsPlaying(false);
      return;
    }
    audioPlayer.play();
    setIsPlaying(true);
  };

  const AudioContent = [
    {
      name: "Mind Calming: Mindfulness Meditation Class",
      description:
        "A yoga class is designed to calm your mind and rejuvenate your body. Through gentle stretches, mindful breathing, and intentional pauses, you’ll connect with your inner peace and release tension. Perfect for anyone seeking a quick yet effective way to center themselves in a busy day.",
      tags: [
        "meditation",
        "mindfulness",
        "relaxation",
        "calm",
        "work stress",
        "breathing",
        "unwind",
        "focus",
      ],
      src: "/audio/sample_yoga_calming.wav",
    },
    {
      name: "Mindful Unwind - After Work",
      description:
        "Mindful Unwind is an 8-minute guided meditation designed to help you release the stress of the day and find calm. Through deep breathing, a gentle body scan, and mindfulness exercises, this class encourages relaxation, clarity, and a renewed sense of focus. Perfect for unwinding after work.",
      tags: [
        "meditation",
        "mindfulness",
        "relaxation",
        "calm",
        "work stress",
        "breathing",
        "unwind",
        "focus",
      ],
      src: "/audio/sample_yoga_calming.wav",
    }
  ];

  const TabThemes = {
    base: "flex flex-col gap-2",
    tablist: {
      base: "flex text-center",
      variant: {
        default: "flex overflow-x-auto",
        underline:
          "-mb-px flex-wrap border-b border-gray-200 dark:border-gray-700",
        pills:
          "flex-wrap space-x-2 text-sm font-medium text-gray-500 dark:text-gray-400",
        fullWidth:
          "grid w-full grid-flow-col divide-x divide-gray-200 rounded-none text-sm font-medium shadow dark:divide-gray-700 dark:text-gray-400",
      },
      tabitem: {
        base: "flex-none px-3 py-6 first:pl-6 last:pr-6 border-r-2 border-l-2 border-t-2 border-blue-400",
        variant: {
          default: {
            base: "rounded-t-lg",
            active: {
              on: "bg-gray-100 text-cyan-600",
              off: "text-gray-500 hover:bg-gray-50 hover:text-gray-600",
            },
          },
          underline: {
            base: "rounded-t-lg",
            active: {
              on: "active rounded-t-lg border-b-2 border-cyan-600 text-cyan-600 dark:border-cyan-500 dark:text-cyan-500",
              off: "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
            },
          },
          pills: {
            base: "",
            active: {
              on: "rounded-lg bg-cyan-600 text-white",
              off: "rounded-lg hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white",
            },
          },
          fullWidth: {
            base: "ml-0 flex w-full rounded-none first:ml-0",
            active: {
              on: "active rounded-none bg-gray-100 p-4 text-gray-900 dark:bg-gray-700 dark:text-white",
              off: "rounded-none bg-white hover:bg-gray-50 hover:text-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white",
            },
          },
        },
        icon: "mr-2 h-5 w-5",
      },
    },
    tabitemcontainer: {
      base: "",
      variant: {
        default: "",
        underline: "",
        pills: "",
        fullWidth: "",
      },
    },
    tabpanel: "py-3",
  };

  return (
    <div className="flex flex-col w-full p-6 mb-8 bg-[#f8f7e2] border border-gray-200 rounded-lg shadow-2xl bg-opacity-80">
      <h2 className="text-4xl text-center font-bold text-gray-900 lg:text-left">
        Mindful Practices
      </h2>
      <div className="w-full mx-auto my-2 grid h-[em] gap-y-8 ">
        <div className="mx-auto my-4 grid max-w-screen-xl gap-y-8 sm:grid-cols-1 lg:grid-cols-1">
          <Tabs aria-label="Pills" variant="default" theme={TabThemes}>
            {AudioContent.map((item, AudioContentIdx) => {
              return (
                <Tabs.Item title={item.name} key={AudioContentIdx}>
                  <div className="group cursor overflow-hidden rounded-2xl bg-white shadow-xl duration-200 hover:-translate-y-4">
                    <div className="flex h-60 flex-col justify-between overflow-hidden">
                      <HaloBreathEffect />
                    </div>
                    <div className="flex-1 overflow-hidden bg-white px-6 py-8">
                      {AudioContentIdx === 0 && (
                        <h1 className="mb-4 text-2xl items-center text-center font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-4xl dark:text-white">
                          <span className="text-blue-600 dark:text-blue-500">
                            This week's Practice
                          </span>
                        </h1>
                      )}
                      <h5 className="group-hover:text-indigo-600 mb-4 text-xl font-bold">
                        {item.name}
                      </h5>
                      <p className="mb-4 text-gray-600">{item.description}</p>
                      <div className="flex flex-wrap items-end justify-center space-x-2 space-y-2">
                        {item.tags.map((tag, tagIdx) => {
                          return <Badge color="light" key={tagIdx}>{tag}</Badge>;
                        })}
                      </div>
                      <div className="flex justify-between mt-8">
                        <button
                          onClick={(e) => handleAudio(item)}
                          className="group text-lg font-bold focus:text-indigo-600 hover:text-indigo-600"
                        >
                          <span className="pr-1">
                            {isPlaying ? "⏸︎" : "⏯︎"}
                          </span>
                          <span className="underline">
                            {isPlaying ? "Pause" : "Listen Now"}
                          </span>
                        </button>
                        <div className="max-w-full flex-none lg:px-4">
                          <h5 className="text-lg font-bold">
                            Duration: 8 Minutes
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Item>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
