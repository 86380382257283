import React, { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import { getTimeAMPMFormat } from "../../../../utils.js";
import { UserLink } from "../../userLanding.css.js";

export function EventBrightLink({ linkData, userHandle }) {
  const [eventsData, setEventsData] = useState({});

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_HTTP_BACKEND_API_HOST}/api/integrations/eventbrite/upcoming?handle=chelsea`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let allIDs = result.data.events.map((item) => item.id);
        var url = new URL(
          `${process.env.REACT_APP_HTTP_BACKEND_API_HOST}/api/integrations/eventbrite/events`
        );

        var params = {
          handle: userHandle,
          event_ids: allIDs.join(","),
        };

        url.search = new URLSearchParams(params).toString();
        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => setEventsData(result.data))
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="flex flex-col w-full">
      <UserLink
        href={linkData.url}
        data-user-url={linkData.url}
        data-thumbnail=""
      >
        <h1>
          <span className="flex nowrap text-center text-xl text-center underline text-transparent border-b border-gray-300 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            {linkData.title}
          </span>
        </h1>
      </UserLink>
      <div className="bg-gradient-to-b from-slate-50 to-blue-300 sm:h-[35em] md:h-[45em] lg:h-[40em] xl:h-[60em] 2xl:h-[60em] px-4 rounded-sm grid grid-cols-1 justify-center items-center">
        <Carousel slide={false}>
          {eventsData?.events &&
            eventsData.events
              .filter((item) => {
                if (
                  new Date(`${item.start_date} ${item.start_time}`).valueOf() <=
                  new Date().valueOf()
                ) {
                  return false;
                }
                if (item.event_sales_status?.sales_status !== "on_sale") {
                  return false;
                }
                return true;
              })
              .map((item, idx) => {
                return (
                  <a
                    key={idx}
                    href={item.url}
                    className="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg"
                  >
                    <div className="relative mx-auto w-full rounded-full">
                      <span className="hidden absolute right-0 m-3 h-3 w-3 rounded-full bg-green-500 ring-2 ring-green-300 ring-offset-2"></span>
                      <img
                        className="mx-auto h-auto w-full rounded-lg"
                        src={item.image.original.url}
                        alt=""
                      />
                    </div>
                    <h1 className="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                      {item.name}
                    </h1>
                    <h3 className="font-lg text-sm text-semibold text-center leading-6 text-gray-600 underline">
                      📍{" "}
                      {item.primary_venue?.address?.localized_address_display}
                    </h3>
                    <p className="font-sm text-lg text-center text-sm leading-6 text-gray-500 hover:text-gray-600">
                      {item.summary}
                    </p>
                    <ul className="mt-3 divide-y rounded bg-gray-100 py-2 px-3 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow">
                      <li className="flex items-center py-3 text-md">
                        <span>Cost</span>
                        <span className="ml-auto">
                          <span className="rounded-full bg-green-200 py-1 px-2 text-md font-medium text-green-700">
                            {item?.ticket_availability?.is_free === true
                              ? "Free"
                              : "$" +
                                item.ticket_availability?.minimum_ticket_price
                                  ?.display}
                          </span>
                        </span>
                      </li>
                      <li className="flex items-center py-3 text-md">
                        <span>Event Date</span>
                        <span className="ml-auto underline">
                          {getTimeAMPMFormat(
                            new Date(`${item.start_date} ${item.start_time}`)
                          )}
                        </span>
                      </li>
                    </ul>
                  </a>
                );
              })}
        </Carousel>
      </div>
    </div>
  );
}
