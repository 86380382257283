import React from "react";

import { UserButton } from "../../userLanding.css.js";

export const SupportFinancial = ({ data }) => {
  const ctas = {
    paypal_me: "Paypal",
    cash_app: "CashApp",
  };

  return (
    <div className="w-full">
      {data?.networks.map((item, idx) => {
        return (
          <UserButton
            key={idx}
            className="flex w-full mb-2 justify-center shadow-[0_4px_14px_0_rgb(0,118,255,39%)] hover:shadow-[0_6px_20px_rgba(0,118,255,23%)] hover:bg-[rgba(0,243,117,0.9)] px-8 py-2 bg-[#58ea70] rounded-md text-white font-bold transition duration-200 ease-linear"
            onClick={() => {
              window.open(item.link, "_blank")
            }}
          >
            <h3>{ctas[item.type]}</h3>
          </UserButton>
        );
      })}
    </div>
  );
};
