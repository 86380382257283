export const getAvatar = (url, seed) => {
  const urlParse = new URL(url);
  if (!urlParse.searchParams.get("seed")) {
    urlParse.searchParams.set("seed", seed);
  }
  return urlParse.toString();
};

export const getTimeAMPMFormat = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  // appending zero in the start if hours less than 10
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return date.toDateString() + " at " + hours + ":" + minutes + " " + ampm;
};

export const wait = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const getUrlBarUserName = () => {
  let r = window.location.pathname.split("/").filter((a, b) => a.length > 0);
  return r;
};
