import React from "react";
import { Carousel } from "flowbite-react";

export function EventInfo({ events }) {
  return (
    <div className="flex flex-col space-y-2 items-center">
      <div className="w-full h-[20em]">
        <Carousel slide={false}>
          {[...events].map((item) => {
            return (
              <figure
                key={+new Date() + Math.random()}
                className="relative max-w-sm transition-all duration-300 cursor-pointer filter grayscale-1 hover:grayscale-0"
              >
                <div>
                  <img
                    className="rounded-lg"
                    src={item.image}
                    alt="image description"
                  ></img>
                </div>
                <figcaption className="absolute px-4 text-lg text-white bottom-6">
                  <p>{item.text}</p>
                </figcaption>
              </figure>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
