import React, { useState, useEffect, useCallback } from "react";

/* Icons */
import MoonLoader from "react-spinners/MoonLoader";
import { ReactComponent as KraylaLogo } from "../../imgs/krayla-logo/default_logo.svg";

/* Relative Imports */
import { PublicUserRepository } from "../../data/api/user.js";
import { DEFAULT_USER_PROFILE } from "../../constants.js";
import { getUrlBarUserName, getAvatar } from "../../utils.js";

/* Styling */
import {
  UserPageContainer,
  ProfileImage,
  CompanyIcon,
  UserTagLineText,
  UserContent,
} from "./userLanding.css.js";

/* Components */
import { RatesCarousel } from "./component/RatesCarousel/RatesCarousel.jsx";
import { BookInstructor } from "./component/BookInstructor/BookInstructor.jsx";
import { SupportFinancial } from "./component/SupportFinancial/SupportFinancial.jsx";
import { ContentSubscriptions } from "./component/ContentSubscriptions/ContentSubscriptions.jsx";
import { SignWaiver } from "./component/SignWaiver/SignWaiver.jsx";
import { ReserveOnlineClass } from "./component/ReserveOnlineClass/ReserveOnlineClass.jsx";
import { PartnershipInfo } from "./component/PartnershipInfo/PartnershipInfo.jsx";
import { Socials } from "./component/Socials/Socials.jsx";
import { EventInfo } from "./component/EventInfo/EventInfo.jsx";
import { Testimonials } from "./component/Testimonials/Testimonials.jsx";
import { QRCodeRender } from "./component/QRCodeRender/QRCodeRender.jsx";
import { SingleLink } from "./component/SingleLink/SingleLink.jsx";
import { EventBrightLink } from "./component/EventBrightLink/EventBrightLink.jsx";

export default function UserView() {
  const [user, setUser] = useState({});

  const playConfetti = () => {
    var count = 200;
    var defaults = {
      origin: { y: 0.7 },
    };

    function fire(particleRatio, opts) {
      window.confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  const loadUser = useCallback(async () => {
    const PublicUserRepo = new PublicUserRepository();
    const fetchedUser = await PublicUserRepo.getUser(
      getUrlBarUserName()[0].toLowerCase()
    );
    setUser(fetchedUser.data);
  }, []);

  useEffect(() => {
    loadUser();
    setTimeout(() => {
      playConfetti();
    }, 2000);
  }, [loadUser]);

  return (
    <>
      {console.warn(user)}
      {user?.currUserNameReal ? (
        <UserPageContainer className="flex flex-col justify-center items-center">
          <QRCodeRender
            userQRCodeDescription={user.userQRCodeDescription}
            currUserNameReal={user.currUserNameReal}
            companyIcon={user.companyIcon}
            userTagline={user.userTagline}
          />
          {user.isLoading ? (
            <MoonLoader size={100} color={"#e4e9ef"} loading={user.isLoading} />
          ) : (
            <UserContent className="flex flex-col justify-center mt-4 sm:w-11/12 md:w-11/12 lg:w-1/2 xl:w-8/12 2xl:w-1/2">
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-row">
                  {user.companyIcon && (
                    <CompanyIcon src={user.companyIcon} alt="Profile" />
                  )}
                  <h3
                    className="text-lg mb-4"
                    id="user-name"
                  >{`${user.companyName}`}</h3>
                </div>
                <span className="img-circle">
                  <ProfileImage
                    className="rounded-full ring-2 ring-green-300 animate__animated animate__pulse"
                    src={
                      user.currUserProfileImage !== ""
                        ? user.currUserProfileImage
                        : getAvatar(DEFAULT_USER_PROFILE.MICAH, 0)
                    }
                    alt="Profile"
                  />
                </span>

                <div className="flex flex-col user-bio items-center">
                  <h3
                    className="text-lg highlight-3"
                    id="user-name"
                  >{`@${user.currUserNameReal}`}</h3>
                  <div id="userTagLine" className="flex flex-row">
                    <UserTagLineText
                      className="text-lg mb-4"
                      id="user-name"
                    >{`${user.userTagline}`}</UserTagLineText>
                  </div>
                  <ReserveOnlineClass
                    data={user?.integrations?.liveClasses}
                    userTagline={user.userTagline}
                    currUserNameReal={user.currUserNameReal}
                    urlUserName={user.currentUrlUserName}
                  />
                  {/* Start Divider */}
                  <div className="w-full h-[0.1em] mt-6 mb-4 border-dashed border-t border-gray-500 divide-y divide-dashed" />
                  {/* End Divider */}

                  {/* User Bio */}
                  <div className="block p-6 mb-8 bg-[#f8f7e2] border border-gray-200 rounded-lg shadow-2xl bg-opacity-80">
                    <p className="text-center text-lg font-normal text-gray-500 lg:text-xl">
                      {user.userBio}
                    </p>
                  </div>

                  <ContentSubscriptions />

                  <span className="flex justify-center" href="#">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                      Support My Work
                    </h5>
                  </span>
                  <SupportFinancial
                    data={user?.integrations?.financialSocials}
                  />

                  {/* Start Divider */}
                  <div className="w-full h-[0.1em] mt-6 mb-4 border-dashed border-t border-gray-500 divide-y divide-dashed" />
                  {/* End Divider */}
                  <Testimonials data={user.testimonials} />
                </div>
              </div>
              <div className="links space-y-3">
                <BookInstructor />
                <SignWaiver data={user?.integrations?.docuSeal} />
                <RatesCarousel data={user.ratesConfiguration} />
                {user.currUserLinks
                  ? Object.values(user.currUserLinks).map((linkData, ldx) => {
                      switch (linkData.type) {
                        case "link":
                          return (
                            <div
                              className="flex"
                              key={+new Date() + Math.random()}
                            >
                              <SingleLink
                                key={+new Date() + Math.random()}
                                linkData={linkData}
                              />
                            </div>
                          );
                        case "event-bright":
                          return (
                            <div
                              className="flex"
                              key={+new Date() + Math.random()}
                            >
                              <EventBrightLink
                                userHandle={user?.handle}
                                key={+new Date() + Math.random()}
                                linkData={linkData}
                              />
                            </div>
                          );
                        default:
                          return <></>;
                      }
                    })
                  : null}
                <Socials data={user.socials} />
                <EventInfo events={user.previousEvents} />

                <PartnershipInfo partnerships={user.partnerships} />
              </div>
            </UserContent>
          )}
          <div className="footer">
            <section className="service-footer">
              <div className="service-logo-wrap">
                <h1>
                  <a href="/">
                    <span className="flex flex-row items-center space-x-2 mt-4">
                      <span>Powered with ❤️ by - </span>
                      <KraylaLogo className="w-14 h-10" />
                    </span>
                  </a>
                </h1>
              </div>
            </section>
          </div>
        </UserPageContainer>
      ) : (
        <></>
      )}
    </>
  );
}
