
import styled from "styled-components";

export const UserPageContainer = styled.div`
  width: 100%;
  height: fit-content;
  background: hsla(323, 100%, 70%, 1);
  background: linear-gradient(
    90deg,
    hsla(323, 100%, 70%, 1) 0%,
    hsla(38, 100%, 63%, 1) 50%,
    hsla(79, 86%, 59%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(323, 100%, 70%, 1) 0%,
    hsla(38, 100%, 63%, 1) 50%,
    hsla(79, 86%, 59%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(323, 100%, 70%, 1) 0%,
    hsla(38, 100%, 63%, 1) 50%,
    hsla(79, 86%, 59%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FF66C4", endColorstr="#FFB840", GradientType=1 );

  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
`;

export const ProfileImage = styled.img`
  display: flex;
  border-radius: 50%;
  width: 5em;
  height: 5em;
  object-fit: cover;
`;

export const PartnerShipImage = styled.img`
  object-fit: cover;
`;

export const CompanyIcon = styled.img`
  display: flex;
  border-radius: 50%;
  width: 100%;
  height: 5em;
  object-fit: cover;
`;

export const UserTagLineText = styled.span`
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
`;

export const UserContent = styled.div``;

export const UserLink = styled.a`
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
  background-color: #ffffff;
  color: #ffffff;
  overflow-wrap: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 56px;
  width: 100%;
  position: relative;
  font-family: "Karla", "Karla", sans-serif;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 16px;
  transition: none;
  transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
`;

export const UserButton = styled.button``;