import React from "react";

import { UserLink } from "../../userLanding.css.js";

export function SingleLink({ linkData }) {

  return (
    <UserLink
      href={linkData.url}
      data-user-url={linkData.url}
      data-thumbnail=""
    >
      <h1 className="text-xl">
        <span className="underline text-transparent border-b border-gray-300 bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          {linkData.title}
        </span>
      </h1>
    </UserLink>
  );
}
